module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"./src/favicons/favicon-32x32.png","icons":[{"src":"./src/favicons/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"./src/favicons/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"./src/favicons/android-chrome-192x192.png","sizes":"192x32192","type":"image/png"},{"src":"./src/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"./src/favicons/apple-touch-icon.png","sizes":"32x32","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"095f6ed5b9ab66d523a92d7c5eec7ab2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Open Sans:400,700,500"]}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
